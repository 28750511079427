

































































































































import { Component, Vue } from 'vue-property-decorator'
import { VeeValidateObserverRef } from '@/models/Common/Validation'
import AuthService from '@/services/AuthService'

@Component
export default class ResetPassword extends Vue {
  $refs!: {
    observer: VeeValidateObserverRef
  }
  private errorConfirmPassword: boolean = false
  private new_password: string = ''
  private confirm_new_password: string = ''
  private isLoading: boolean = false
  private isResetPassword: boolean = false
  private showErrorMsg: boolean = false

  created() {
    let data = {
      token: this.$route.query.token
    }
    AuthService.checkTokenExpired(data)
      .then(response => {
        if (response.status === 200) {
          return
        }
      })
      .catch(error => {
        this.$router.push({
          name: 'login',
          query: { reset_password_expired: 'true' }
        })
      })
  }

  resetPassword() {
    this.$refs.observer.validate().then(async isValidate => {
      if (isValidate) {
        if (this.new_password !== this.confirm_new_password) {
          this.errorConfirmPassword = true
        } else {
          this.isLoading = true
          const data = {
            password: this.new_password,
            password_confirmation: this.confirm_new_password,
            token: this.$route.query.token
          }
          await AuthService.resetPassword(data)
            .then(res => {
              if (res.status === 200) {
                this.showErrorMsg = false
                this.isResetPassword = true
              }
            })
            .catch(error => {
              this.showErrorMsg = true
            })
          this.isLoading = false
          this.errorConfirmPassword = false
        }
      } else {
        return null
      }
    })
  }

  resetMsg() {
    this.showErrorMsg = false
    this.errorConfirmPassword = false
  }

  goToLogin() {
    this.$router.push({ name: 'login' })
  }
}
